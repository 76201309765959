@media (min-width: 0) {
  .image-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4em auto;
  }

  .skill-image {
    width: 100%;
    padding: 4% 0;
    text-align: center;
  }
  .skill-image:hover {
    opacity: .7;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .skill-image:nth-child(2n + 1) {
    border-left: none;
    border-bottom: none;
  }

  .skill-image:nth-child(2n + 2) {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .skill-image:nth-child(-n + 2) {
    border-top: none;
  }

  .skill-image:nth-child(n+7) {
    border-bottom: none;
  }
  
}
@media (min-width: 320px) {
  .skill-image {
    width: 45%;
    padding: 2%;
    border: 2px solid grey;
  }
}

@media (min-width: 768px) {
  .skill-image {
    width: 18%;
    padding: 2%;
  }

  .skill-image:nth-child(4n + 2) {
    margin: 0 -2px -2px -2px;
  }

  .skill-image:nth-child(4n + 4) {
    margin-left: -2px;
    border-right: none;
  }

  .skill-image:nth-child(2n + 3) {
    margin-bottom: -2px;
  }

  .skill-image:nth-child(4n + 1) {
    border-left: none;
    border-bottom: none;
  }

  .skill-image:nth-child(-n + 4) {
    border-top: none;
  }
  
  .skill-image:nth-child(n+4) {
    border-bottom: none;
  }

  .image-wrapper {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .image-wrapper {
    width: 75%;
  }
}