@media (min-width: 0) {
  .portfolio-images-wrapper {
    display: flex;
    flex-direction: column;
  }

  .portfolio-image {
    text-align: center;
    margin-bottom: 20px;
  }

  .portfolio-image p {
    width: 70%;
    margin: 2% auto;
  }

  .portfolio-image img {
    width: 80%;
  }

}

@media (min-width: 768px) {
  .portfolio-images-wrapper {
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .portfolio-image {
    width: 40%;
    margin-bottom: 5%;
  }

  .portfolio-image p {
    width: 60%;
  }
}

@media (min-width: 1280px) {
  .portfolio-image {
    width: 22%;
  }
}