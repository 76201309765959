.about-me {
  text-align: center;
}
.left {
  margin-bottom: 30px;
}
.about-me-text {
  width: 80%;
  margin: auto;
}

@media(min-width: 768px) {
  .about-me-text {
    width: 100%;
    font-weight: 500;
    font-size: 1.1em;
  }
}
