@media (min-width: 0) {
  nav {
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 2;
  }

  .navigation-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    padding: 0;
  }


  .navigation-header li {
    margin: 0 3px;
  }
  
  .navigation-header li:first-of-type {
    margin-right: auto;
    font-size: 1em;
    font-weight: lighter;
  }
  
  .navigation-header li:first-of-type span {
    border: 3px solid white;
    padding: .3em 0;
  }
  
  .navigation-header li:first-of-type p {
    display: inline;
    padding: .3em;
  }
  
  .navigation-header li:first-of-type p:first-of-type {
    color: white;
    background: grey;
  }
  
  .navigation-header li:first-of-type p:last-of-type {
    color: grey;
  }
  
  .navigation-header a {
    text-decoration: none;
    color: grey;
  }
}



@media (max-width: 320px) {
  .navigation-header li:first-of-type {
    display: none;
  }
}

@media(max-width: 420px) {
  .navigation-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    padding: 0;
  }
}

@media(min-width: 420px) {
  .navigation-header li {
    margin: 0 15px;
  }
}
