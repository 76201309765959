@media (min-width: 0) {
  h3 {
    text-align: center;
    font-size: 2em;
    color: grey;
    padding: 1.5em;
  }
}

@media (min-width: 768px) {
  h3 {
    font-size: 3em;  
    padding: 1em;  
  }
}