@media (min-width: 0) {
  .google-map > div {
    z-index: -1;
  }
  #contact {
    color: #fff;
    position: relative;
    text-align: center;
  }
  .contact {
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    width: 100%;
    margin-left: -50%;
    margin-right: -50%;
  }
  .mail {
    color: #fff;
    display: inline-block;
    margin: 0 auto 50px;
  }
  .contact-images {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
  }
  .contact-images a:nth-child(2) {
    margin: 0 0 0 20px;
  }
}

