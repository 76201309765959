.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    background-position: left;
    background-size: cover;
}

.hero-image div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.hero-image p, .hero-image h2, .hero-image h1 {
    color: #fff;
    font-size: 2em;
    text-align: end;
    margin: 5px;
    font-weight: 400;
}
@media (min-width: 420px) {
  .hero-image div {
    margin: 0 -30% 0 0;
  }
}
@media (min-width: 768px) {
  .hero-image p, .hero-image h2, .hero-image h1 {
    font-size: 3em;
  }
  .hero-image div {
    margin: -5% -28% 0 0;
  }
}